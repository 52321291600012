import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {isValidHttpUrl} from '../utils/isValidHttpUrl';
import {ILink, PageMap} from '@wix/wixstores-client-core';
import {SPECS} from '../../components/Checkout/constants';

export interface CheckoutAppSectionParams {
  checkoutId?: string;
  continueShoppingUrl?: string;
  successUrl?: string;
  cashierPaymentId?: string;
  isPickupFlow?: boolean;
  origin?: string;
  originType?: string;
}

export class NavigationService {
  public readonly appSectionParams: CheckoutAppSectionParams;
  public readonly currency?: string;
  private readonly siteStore: SiteStore;
  public isLoaded: boolean;
  private continueShoppingLink!: ILink;
  private externalSuccessUrl?: ILink;
  private fetchedEditCartUrl!: string;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.isLoaded = false;
    this.siteStore = siteStore;
    this.appSectionParams = this.getAppSectionParams();
    this.currency = this.getCurrency();
  }

  public async load() {
    this.continueShoppingLink = await this.fetchContinueShoppingLink();
    this.fetchedEditCartUrl = await this.fetchEditCartUrl();
    this.externalSuccessUrl = this.fetchExternalSuccessUrl();
    this.isLoaded = true;
  }

  public navigateToOldCheckout() {
    const url = new URL(this.siteStore.location.url);
    url.searchParams.delete('checkoutOOI');
    this.siteStore.navigateToLink(this.getExternalLink(url.toString()));
  }

  private async fetchContinueShoppingLink(): Promise<ILink> {
    const continueShoppingUrl = this.appSectionParams.continueShoppingUrl;
    return continueShoppingUrl && isValidHttpUrl(continueShoppingUrl)
      ? this.getExternalLink(continueShoppingUrl)
      : this.siteStore.getHomepageLink();
  }

  private fetchExternalSuccessUrl(): ILink | undefined {
    const externalSuccessUrl = this.appSectionParams.successUrl;
    return externalSuccessUrl && isValidHttpUrl(externalSuccessUrl)
      ? this.getExternalLink(externalSuccessUrl)
      : undefined;
  }

  private async fetchEditCartUrl(): Promise<string> {
    const {url} = await this.siteStore.getSectionUrl(PageMap.CART);
    return `${url!}?appSectionParams=${encodeURIComponent(JSON.stringify({origin: 'checkout'}))}`;
  }

  public navigateToContinueShopping(): void {
    return this.siteStore.navigateToLink(this.continueShoppingLink);
  }

  public navigateToThankYouPage(orderId: string): Promise<void> | void {
    if (this.externalSuccessUrl) {
      return this.navigateToExternalSuccessUrl(orderId);
    }

    return this.siteStore.navigate(
      {
        sectionId: PageMap.THANKYOU,
        queryParams: {
          objectType: 'order',
          origin: 'checkout',
          continueShoppingUrl: this.continueShoppingLink.url,
        },
        state: orderId,
      },
      true
    );
  }

  public navigateToCart(cartId?: string): Promise<void> {
    return this.siteStore.navigate(
      {
        sectionId: PageMap.CART,
        queryParams: {
          objectType: 'order',
          origin: 'checkout',
        },
        state: cartId,
      },
      true
    );
  }

  private getCurrency() {
    return this.siteStore.location.query.currency;
  }

  private getAppSectionParams(): CheckoutAppSectionParams {
    const appSectionParams = this.siteStore.location.query.appSectionParams;
    if (!appSectionParams) {
      return {};
    }
    try {
      return JSON.parse(appSectionParams) as CheckoutAppSectionParams;
    } catch {
      /* istanbul ignore next */
      return {};
    }
  }

  private getExternalLink(url: string): ILink {
    return {url, sdkLink: {type: 'ExternalLink', target: '_top', url}};
  }

  private navigateToExternalSuccessUrl(orderId: string): void {
    return this.siteStore.navigateToLink(this.getExternalLink(`${this.externalSuccessUrl!.url}?orderId=${orderId}`));
  }

  public isMember(): boolean {
    return Boolean(this.siteStore.usersApi.currentUser?.loggedIn);
  }

  public get continueShoppingUrl() {
    return this.continueShoppingLink.url;
  }

  public get isFastFlow() {
    return Boolean(this.appSectionParams?.cashierPaymentId);
  }

  public get isVisitorPickupFlow() {
    return Boolean(
      this.siteStore.experiments.enabled(SPECS.UseNewCheckoutInVisitorPickup) &&
        this.appSectionParams.isPickupFlow &&
        !this.isMember()
    );
  }

  public get isMemberPickupFlow() {
    return Boolean(
      this.siteStore.experiments.enabled(SPECS.UseNewCheckoutInMemberPickupFlow) &&
        this.appSectionParams.isPickupFlow &&
        this.isMember()
    );
  }

  public get isFastFlowWithPickupFlow() {
    return Boolean(this.isFastFlow && this.appSectionParams?.isPickupFlow);
  }

  public get origin() {
    return this.appSectionParams.origin;
  }

  public get originType() {
    return this.appSectionParams.originType;
  }

  public get editCartUrl() {
    return this.fetchedEditCartUrl;
  }
}
