import {CheckoutSettingsService} from '../services/CheckoutSettingsService';
import {CheckoutService} from '../services/CheckoutService';

export const shouldShowGiftCardSection = ({
  checkoutSettingsService,
  checkoutService,
}: {
  checkoutSettingsService: CheckoutSettingsService;
  checkoutService: CheckoutService;
}): boolean => {
  return (
    checkoutSettingsService.checkoutSettings.isGiftCardEnabled &&
    checkoutSettingsService.checkoutSettings.isGiftCardSupported &&
    !checkoutService.checkout.hasSubscriptionItems &&
    !checkoutService.hasGiftCardItem()
  );
};
